import {
	Button,
	FlexRow,
	Icon,
	Input,
	InputContainer,
	Label,
	NoAutoFillInput,
	NumberInput,
	RequiredMarker,
	Tooltip,
} from "@components";
import { colors } from "@theme/theming";
import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

function JobRadSettings() {
	const { control, getValues } = useFormContext();
	const [companyId, setCompanyId] = useState<number | null>(null);
	const [companyUrl, setCompanyUrl] = useState("");
	const [isUniqueId, setIsUniqueId] = useState(true);
	const [isUniqueUrl, setIsUniqueUrl] = useState(true);
	const [isValidUrl, setIsValidUrl] = useState(true);

	const { fields, prepend, remove } = useFieldArray({
		control,
		name: "providerSettings.jobrad.companies",
		rules: { required: true, minLength: 1 },
	});

	const addCompany = () => {
		if (companyId && companyUrl && isUniqueId && isUniqueUrl && isValidUrl) {
			prepend({
				id: companyId,
				url: companyUrl,
			});
			setCompanyId(null);
			setCompanyUrl("");
		}
	};
	const removeCompany = (index: number) => {
		remove(index);
	};
	const handleEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			addCompany();
		}
	};

	return (
		<Container>
			<InputContainer>
				<Label htmlFor="frameContractId">
					Rahmenvertrags ID <RequiredMarker />{" "}
					<Tooltip
						id="frameContractIdTooltip"
						tooltip="Bei Trial-Standorten können nur die Rahmenvertrags-IDs 111111 (kein Abruf von Events) oder 123456 (Verbindung zu JobRad LOFINO Dev) verwendet werden."
					>
						<Icon.Info color={colors.primary.p200} />
					</Tooltip>
				</Label>
				<Controller
					name="providerSettings.jobrad.frameContractId"
					control={control}
					render={({ field }) => (
						<NumberInput {...field} id="frameContractId" required />
					)}
				/>
			</InputContainer>
			<InputContainer>
				<CompanyList>
					<NewCompanyForm>
						<InputContainer>
							<Label>
								Unternehmens ID{" "}
								<RequiredMarker
									message={!isUniqueId ? "Die ID sollte eindeutig sein" : ""}
								/>
							</Label>
							<NumberInput
								value={companyId}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const isUniqueId = !fields.some((_, index) => {
										const id = getValues(
											`providerSettings.jobrad.companies.${index}.id`
										);
										return Number(e.target.value) === Number(id);
									});
									setIsUniqueId(isUniqueId);
									setCompanyId(Number(e.target.value) ?? null);
								}}
								onKeyPress={handleEnterPressed}
							/>
						</InputContainer>
						<InputContainer>
							<Label>
								Unternehmens URL{" "}
								<RequiredMarker
									message={
										!isValidUrl
											? "Ungültige URL"
											: !isUniqueUrl
											? "Die URL sollte eindeutig sein"
											: ""
									}
								/>
							</Label>
							<Input
								value={companyUrl}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const isUniqueUrl = !fields.some((_, index) => {
										const url = getValues(
											`providerSettings.jobrad.companies.${index}.url`
										);
										return e.target.value === url;
									});
									const isValidUrl = yup
										.string()
										.url()
										.isValidSync(e.target.value);
									setIsValidUrl(isValidUrl);
									setIsUniqueUrl(isUniqueUrl);
									setCompanyUrl(e.target.value);
								}}
								as={NoAutoFillInput}
								onKeyPress={handleEnterPressed}
							/>
						</InputContainer>
						<AddCompanyButton
							onClick={addCompany}
							variant="outline-primary"
							className="form-control"
						>
							<Icon.Add />
						</AddCompanyButton>
					</NewCompanyForm>
					{fields.map((_, index) => {
						return (
							<NewCompanyForm key={index}>
								<AddedCompany className="form-control">
									{getValues(`providerSettings.jobrad.companies.${index}.id`)}
								</AddedCompany>
								<AddedCompany className="form-control">
									{getValues(`providerSettings.jobrad.companies.${index}.url`)}
								</AddedCompany>
								<RemoveCompanyButton
									onClick={() => removeCompany(index)}
									variant="outline-danger"
									className="form-control"
								>
									<RemoveCompanyIcon />
								</RemoveCompanyButton>
							</NewCompanyForm>
						);
					})}
				</CompanyList>
			</InputContainer>
		</Container>
	);
}

const Container = styled.div`
	background: ${colors.gray.g100};
	border-radius: 5px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;

const CompanyList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.7rem;
`;

const NewCompanyForm = styled(FlexRow)`
	column-gap: 1rem;
	align-items: flex-end;
`;

const AddCompanyButton = styled(Button)`
	padding-block: 0 !important;
	padding-inline: 1rem !important;
	width: auto;
`;

const RemoveCompanyButton = styled(Button)`
	padding-block: 0 !important;
	background: transparent !important;
	border: none;
	padding-inline: 0.9rem !important;
	width: auto;
`;

const RemoveCompanyIcon = styled(Icon.Delete)`
	font-size: 1.2rem;
`;

const AddedCompany = styled.span`
	flex: 1;
	background: ${colors.gray.g100};
`;

export default JobRadSettings;

import { ErrorBase } from "@util/errors";

export class GetAllDivisionError extends Error {
	constructor() {
		super("Error while fetching all division");
	}
}

export class DivisionNotFoundError extends ErrorBase {
	constructor() {
		super("Standort wurde nicht gefunden");
	}
}
export class GetDivisionByIdError extends Error {
	constructor() {
		super("Error while fetching customer by id");
	}
}

export class AddDivisionError extends Error {
	constructor() {
		super("Error while adding new customer");
	}
}

export class UpdateDivisionError extends Error {
	constructor() {
		super("Error while updating customer");
	}
}

export class DeleteDivisionError extends Error {
	constructor() {
		super("Cannot delete customer, vouchers uploaded by users");
	}
}

export class DeleteDivisionWithVoucherError extends Error {
	constructor() {
		super("Cannot delete division, vouchers uploaded by users");
	}
}

export class ChangeDivisionStatusError extends ErrorBase {
	constructor() {
		super(
			"Fehler. Status konnte nicht geändert werden. Wende dich an Engineering."
		);
	}
}

import React, { CSSProperties, useContext } from "react";
import {
	Controller,
	ControllerRenderProps,
	FieldPath,
	FieldValues,
	useFormContext,
} from "react-hook-form";
import styled from "styled-components";
import { isNumber } from "lodash";
import { Voucher } from "@models";
import {
	Form,
	CurrencyInput,
	Label,
	TextArea,
	NoAutoFillInput,
	CheckboxToggle,
} from "@components";
import DatePicker from "../../../forms/DatePicker";
import { formatDate } from "@components/forms/datePickerUtils";
import { Currency } from "@util";
import VatAmounts from "./VatAmounts";
import { ReadonlyText } from "./ReadonlyText";
import VoucherReviewAmount from "./VoucherReviewAmount";
import _ from "lodash";
import useTripReviewContext from "../../../../pages/trips/hooks/useTripReviewContext";
import ReviewContext from "../context/ReviewContext";
import DuplicateWarning from "./DuplicateWarning";
import { colors } from "@theme/theming";
import FraudWarning from "./FraudWarning";
import { AutoFillInput } from "@components/forms/Input";
import { Alert } from "react-bootstrap";

type Props = {
	voucher: Voucher;
	style?: CSSProperties;
	onClose?: () => void;
};

export default function VoucherReviewForm(props: Props) {
	const {
		style,
		voucher: {
			voucherId,
			amountRefunded,
			description,
			vatAmounts,
			didConfirmWeekendWork,
		},
		voucher,
		onClose,
	} = props;

	const { control, register } = useFormContext<FieldValues>();

	const { hasReviewContext: hasTripReviewContext, isTripEditable } =
		useTripReviewContext();
	const hasVoucherReviewContext = !!useContext(ReviewContext);
	const hasReviewContext = hasTripReviewContext
		? isTripEditable
		: hasVoucherReviewContext;

	const thirdPartyVoucherAllowed = voucher.category.startsWith("mobility");

	function name(field: string): string {
		return hasTripReviewContext ? `vouchers.${voucherId}.${field}` : field;
	}

	function id(field: string): string {
		return `${voucherId}-${field}`;
	}

	function inputOrText<
		TFieldValues extends FieldValues,
		TName extends FieldPath<TFieldValues>
	>(fieldName: TName, field: ControllerRenderProps<TFieldValues, TName>) {
		return hasReviewContext ? (
			<AutoFillInput {...field} id={id(fieldName)} />
		) : (
			<ReadonlyText value={field.value} id={id(fieldName)} />
		);
	}

	const showFraudAlert = hasVoucherReviewContext && voucher.fraudProbability;

	const amountSection = (
		<>
			<SectionHeader>Beleg</SectionHeader>
			<StyledLabel>Belegdatum</StyledLabel>
			<Controller
				render={({ field }) =>
					hasReviewContext ? (
						<FormDatePicker>
							<DatePicker {...field} />
						</FormDatePicker>
					) : (
						<ReadonlyText value={formatDate(field.value)} />
					)
				}
				control={control}
				defaultValue={new Date()}
				name={name("dateOnVoucher")}
			/>

			<StyledLabel htmlFor={id("amountAfterReview")}>
				Betrag (geprüft)
			</StyledLabel>
			{vatAmounts !== null ? (
				<VatAmounts voucherId={voucherId} />
			) : (
				<VoucherReviewAmount
					id={id("amountAfterReview")}
					name={name("amountAfterReview")}
				/>
			)}

			{isNumber(amountRefunded) && (
				<>
					<StyledLabel htmlFor={id("amountRefunded")}>
						Erstattung der Krankenkasse
					</StyledLabel>
					<Controller
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						render={({ field: { ref, ...props } }) =>
							hasReviewContext ? (
								<CurrencyInput id={id("amountRefunded")} {...props} />
							) : (
								<ReadonlyText value={Currency.format(props.value)} />
							)
						}
						control={control}
						name={name("amountRefunded")}
					/>
				</>
			)}

			{/* TODO: add tip amount for expense voucher */}
			{!!description && (
				<>
					<StyledLabel htmlFor={id("description")}>Bezeichnung</StyledLabel>
					<Controller
						render={({ field }) =>
							hasReviewContext ? (
								<NoAutoFillInput id={id("description")} {...field} />
							) : (
								<ReadonlyText id={id("description")} value={field.value} />
							)
						}
						control={control}
						defaultValue=""
						name={name("description")}
					/>
				</>
			)}
			{thirdPartyVoucherAllowed && (
				<>
					<StyledLabel htmlFor={id("isFromThirdParty")}>
						Beleginhaber
					</StyledLabel>
					<CheckboxToggle
						label="Beleg von Drittperson"
						{...register("isFromThirdParty")}
					/>
				</>
			)}
			{!!showFraudAlert && <FraudWarning voucher={voucher} />}
			{hasVoucherReviewContext && (
				<DuplicateWarning onReject={onClose} voucher={voucher} />
			)}
		</>
	);

	const supplierSection = (
		<>
			<SectionHeader>Lieferant</SectionHeader>

			<StyledLabel htmlFor={id("supplierName")}>Name</StyledLabel>
			<Controller
				render={({ field }) => inputOrText("supplierName", field)}
				control={control}
				defaultValue=""
				name={name("supplierName")}
			/>
		</>
	);

	const commentSection = (
		<>
			<SectionHeader>Kommentar</SectionHeader>

			<StyledLabel htmlFor={id("backofficeComment")}>
				Prüferkommentar
			</StyledLabel>
			<Controller
				render={({ field }) =>
					hasReviewContext ? (
						<TextArea
							{...field}
							value={_.unescape(field.value) ?? ""}
							rows="5"
							id={id("backofficeComment")}
						/>
					) : (
						<ReadonlyText value={field.value} />
					)
				}
				control={control}
				defaultValue=""
				name={name("backofficeComment")}
			/>
		</>
	);

	return (
		<>
			{voucher.isDraft && (
				<Alert variant="danger">
					Dieser Beleg wurde zurückgezogen und befindet sich nun im
					Entwurfsstatus. Dies kann passieren, wenn der Nutzer den Beleg
					zurückzieht, während du ihn im Backoffice überprüfst.
				</Alert>
			)}
			<Form style={style}>
				{didConfirmWeekendWork && (
					<GridContainer>
						Benutzer hat die Wochenendarbeit bestätigt.
					</GridContainer>
				)}
				<GridContainer>
					{amountSection}
					{supplierSection}
					{commentSection}
				</GridContainer>
			</Form>
		</>
	);
}

const StyledLabel = styled(Label)`
	grid-column: 1;
	color: ${colors.gray.g400};
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8px;
	row-gap: 2px;
	align-items: baseline;
	width: 100%;
`;

const SectionHeader = styled.h1`
	grid-column: 1 / span 2;
	font-size: 1.25rem;
	font-weight: bold;
	color: ${colors.primary.p300};
	margin-top: 8px;
`;

// aligns the date input with the rest of the form inputs
// without affecting the DatePicker everywhere else
const FormDatePicker = styled.div`
	> :first-child {
		width: 100%;

		> :first-child {
			margin: 0;
		}
	}
`;

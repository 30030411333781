import {
	BudgetMasterCollisionError,
	SachbezugErrors,
} from "@service/product/errors";
import { DeserializableFromResponse, ErrorBase } from "@util/errors";
import {
	MonthClosedForChangesError,
	ThirdPartyCategoryNotAllowedError,
	ThirdPartyVouchersNotEnabledError,
} from "./errors";
import {
	AccountingMonthIllegalStatusTransitionError,
	ExcessBudgetDisabledError,
	InvalidReportSettingsError,
	UnreviewedVouchersError,
	WageKeyMissingError,
	WageTypeMissingError,
} from "@service/accountingMonth/error";

/** a list of known errors that are DeserializableFromResponse */
export const knownErrors: DeserializableFromResponse<ErrorBase>[] = [
	ExcessBudgetDisabledError,
	BudgetMasterCollisionError,
	MonthClosedForChangesError,
	ThirdPartyVouchersNotEnabledError,
	ThirdPartyCategoryNotAllowedError,
	UnreviewedVouchersError,
	AccountingMonthIllegalStatusTransitionError,
	WageTypeMissingError,
	WageKeyMissingError,
	InvalidReportSettingsError,
	...SachbezugErrors,
];

import { getErrorFromResponse } from "@util/errors";
import {
	InvalidSalutationTemplateError,
	ResalutateUnexpectedStateError,
	SendingEmailError,
	UpdateUserError,
	UserConflictError,
	UserNotFoundError,
	InvalidEmailFormatError,
	PersonalnoConflictError,
	CyclicReferenceError,
	OverlappingUsersError,
	LinkExternalUserError,
} from "./errors";
import { MonthString } from "@util/types";
import { MonthClosedForChangesError } from "@service/common/errors";

export function throwErrorWithMessages(e: any): never {
	const data = getErrorFromResponse(e);
	tryThrowErrorWithMessagesFromResponse(data);
	throw e;
}

export function tryThrowErrorWithMessagesFromResponse(data: {
	type: string | undefined;
	user?: string;
	personalNo?: string;
	month?: MonthString;
}): void {
	const { type, user, personalNo, month } = data;
	switch (type) {
		case "user-not-found":
			throw new UserNotFoundError(`Benutzer wurde nicht gefunden ${user}`);
		case "accounting-month-closed":
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			throw new MonthClosedForChangesError(month!);
		case "duplicate-email":
			throw new UserConflictError("email");
		case "duplicate-personal-no":
			throw new UserConflictError("personalNo");
		case "duplicate-external-id":
			throw new UserConflictError("externalId");
		case "overlapping-users":
			throw new OverlappingUsersError(
				"Im angegebenen Zeitraum gibt es einen anderen Benutzer mit derselben externen ID. Die Start- und Endmonate zweier solcher Benutzer dürfen sich nicht überschneiden."
			);
		case "link-external-user-conflict":
			throw new LinkExternalUserError(
				"Es existiert bereits ein externer User mit dieser Email. Neue User können nicht mit der gleichen Email angelegt werden. Der User muss manuell verknüpft werden."
			);
		case "resalutate-unexpected-state":
			throw new ResalutateUnexpectedStateError();
		case "invalid-salutation-template":
			throw new InvalidSalutationTemplateError();
		case "sending-email-failed":
			throw new SendingEmailError();
		case "user-salutation-update-failed":
			throw new UpdateUserError();
		case "user-email-invalid-format":
			throw new InvalidEmailFormatError();
		case "personal-no-conflict":
			throw new PersonalnoConflictError(
				`Konnte die Arbeitnehmer nicht verschieben: PersonalNo ${personalNo} ist am Zielstandort bereits vergeben`
			);
		case "cyclic-reference":
			throw new CyclicReferenceError(
				"Mitarbeitende dürfen nicht zirkulär auf sich selbst verweisen. Bitte prüfe die Verweise der ausgewählten Reisekostenprüfer."
			);
	}
}

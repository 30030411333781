import { ErrorBase } from "@util/errors";

export class UpsertAssetDivisionError extends ErrorBase {
	constructor(reason?: string) {
		super(
			`Die Abteilung wurde gespeichert, aber die JobRad-Einstellungen konnten nicht gespeichert werden.\nGrund: ${
				reason ?? "unbekannt"
			}`
		);
	}
}

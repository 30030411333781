import {
	BalanceAccount,
	BalanceAccountWithTransactions,
	BalanceTransaction,
	Budget,
	BudgetMaster,
	User,
	UserWithBudgetMasters,
} from "@models";
import { UserRole } from "@models/UserRole";
import {
	NewUser,
	UpdatedUser,
	UserStatus,
	UserWithBalanceAccounts,
} from "../../models/User";
import { MoveUserMode } from "../../pages/division/components/MoveUsers/MoveUsersSelection";
import * as apiService from "../api.service";
import { PaginationResult, QueryOptions } from "../types";
import { mapPaginationResponse } from "../util";
import { DebitorNumberNotFoundError } from "./errors";
import { MonthSummary } from "./type";
import {
	throwErrorWithMessages,
	tryThrowErrorWithMessagesFromResponse,
} from "./util";
import { MonthString } from "@util/types";

export async function getUserById(
	userId: string
): Promise<UserWithBalanceAccounts> {
	const user = await apiService.GET("user/" + userId);
	return {
		...User.fromJson(user.data),
		balanceAccounts: user.data.balanceAccounts.map(
			(ba: BalanceAccountWithTransactions) => {
				return {
					...BalanceAccount.fromJson(ba),
					transactions: ba.transactions.map(BalanceTransaction.fromJson),
				};
			}
		),
	};
}

export async function getUsersByDivision(
	divisionId: string,
	options: QueryOptions
): Promise<PaginationResult<UserWithBudgetMasters>> {
	const { limit, page, sort, order } = options;
	const result = await apiService.GET(`user?divisionId=${divisionId}`, {
		params: {
			limit,
			page,
			sort,
			order,
		},
	});
	return mapPaginationResponse(result.data, (json) => ({
		...User.fromJson(json),
		budgetMasters: json.budgetMasters.map((bm: any) =>
			BudgetMaster.fromJson(bm)
		),
	}));
}

export async function addUser(user: NewUser): Promise<User> {
	try {
		const newUser = await apiService.POST("user", User.convertForJson(user));
		return User.fromJson(newUser.data);
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function updateUser(
	user: UpdatedUser,
	userId: string
): Promise<User> {
	try {
		const updatedUser = await apiService.PUT(
			`user/${userId}`,
			User.convertForJson(user)
		);
		return User.fromJson(updatedUser.data);
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

type ErrorPayload = {
	user: string;
} & (
	| {
			type: "user-not-found" | "unknown";
	  }
	| {
			type: "accounting-month-closed";
			month: MonthString;
	  }
);

type DeleteUsersResponse = {
	successful: number;
	errors: ErrorPayload[];
};

export async function deleteUser(userId: string): Promise<void> {
	const response = await apiService.DELETE(`user`, [userId]);
	const result: DeleteUsersResponse = response.data;
	if (!result.successful) {
		tryThrowErrorWithMessagesFromResponse(result.errors[0]);
		throw new Error("Unexpected response");
	}
}

export async function deleteUsers(
	userIds: string[]
): Promise<DeleteUsersResponse> {
	const response = await apiService.DELETE(`user`, userIds);
	return response.data;
}

export async function resalutate(userId: string): Promise<void> {
	try {
		await apiService.POST(`user/${userId}/resalutate`);
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function salutateUser(userIds: string[]): Promise<void> {
	try {
		await apiService.POST("user/salutate", { userList: userIds });
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function updateExcessBudgetForUsers(
	userIds: string[],
	month: Date,
	divisionId: string
): Promise<void> {
	try {
		await apiService.POST("user/excessBudgetUpdate", {
			userList: userIds,
			month,
			divisionId,
		});
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function getAccountSummaryByYear(
	userId: string,
	year: number
): Promise<MonthSummary[]> {
	const { data } = await apiService.GET(`user/${userId}/budgets/${year}`);

	return data.map((monthSummary: MonthSummary) => ({
		date: new Date(monthSummary.date),
		budgets: monthSummary.budgets.map(Budget.fromJson),
		moduleSettings: monthSummary.moduleSettings.map(BudgetMaster.fromJson),
	}));
}

export type MoveUsersPayload = {
	userIds: string[];
	targetDivisionId: string;
	mode: MoveUserMode;
	options?: {
		targetStartingDate: string | null;
		moveVouchers: boolean;
		copyBudgetMasters: boolean;
	};
};

export async function moveUsersToAnotherDivision(
	moveUsersPayload: MoveUsersPayload
) {
	try {
		await apiService.POST(`user/move`, moveUsersPayload);
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function getUserAuthenticationStatus(
	userId: string
): Promise<{ userStatus: UserStatus }> {
	const { data } = await apiService.GET(`user/${userId}/authenticationStatus`);
	return data;
}

export async function getRoles(userId: string): Promise<UserRole[]> {
	const { data } = await apiService.GET(`user/${userId}/roles`);
	return data.map((r: any) => UserRole.fromJson(r));
}

export async function checkSSO(
	email: string,
	abortSignal?: AbortSignal
): Promise<boolean> {
	try {
		const response = await apiService.GET("user/issso", {
			params: {
				email,
			},
			signal: abortSignal,
		});
		return response.data.isSSOUser as boolean;
	} catch (e) {
		throwErrorWithMessages(e);
	}
}

export async function downloadSachbezugUserCsv(
	divisionId: string
): Promise<string> {
	try {
		const response = await apiService.GET(
			`/sachbezug/orderCoupons/division/${divisionId}`
		);
		return window.URL.createObjectURL(new Blob([response.data]));
	} catch (error: any) {
		if (error?.response?.data?.code === "debitor-number-not-found") {
			throw new DebitorNumberNotFoundError(
				"Die Debitor-Nummer für die Division ist nicht gesetzt. Bitte überprüfen Sie SevDesk."
			);
		}
		throw error;
	}
}

import { ErrorBase } from "@util/errors";

export class ExcessBudgetDisabledError extends ErrorBase {
	static errorId = "excess-budget-disabled" as const;
	constructor() {
		super(
			"Der Monat kann nicht geschlossen werden, da es einen Fehler bei der Konfiguration des Überschussbudgets gibt. \n Bitte deaktiviere das Überschussbudget bei den betroffenen Usern oder aktivere das Überschussbudget in den Standorteinstellungen. "
		);
	}
}

export class UnreviewedVouchersError extends ErrorBase {
	static errorId = "unreviewed-vouchers" as const;
	constructor() {
		super(
			"Der Monat kann nicht geschlossen werden, da noch ungeprüfte Belege vorhanden sind. Sobald alle Belege akzeptiert oder abgelehnt worden sind, kannst du den Monat schließen"
		);
	}
}

export class AccountingMonthIllegalStatusTransitionError extends ErrorBase {
	static errorId = "accounting-month-illegal-status-transition" as const;
	constructor() {
		super(
			"Objekt wurde in der Zwischenzeit geändert, bitte neu laden und nochmal probieren"
		);
	}
}

export class WageTypeMissingError extends ErrorBase {
	static fromErrorResponse(payload: any): WageTypeMissingError {
		return new WageTypeMissingError(payload.taxTypeName);
	}

	static errorId = "wage-type-missing" as const;

	override get displayText(): string {
		return `Lohnart nicht gesetzt - ${this.taxTypeName}.`;
	}

	constructor(readonly taxTypeName: string) {
		super();
	}
}

export class WageKeyMissingError extends ErrorBase {
	static fromErrorResponse(payload: any): WageKeyMissingError {
		return new WageKeyMissingError(payload.taxTypeName);
	}

	static errorId = "wage-key-missing" as const;

	override get displayText(): string {
		return `Lohnschlüssel nicht gesetzt - ${this.taxTypeName}.`;
	}

	constructor(readonly taxTypeName: string) {
		super();
	}
}

export class InvalidReportSettingsError extends ErrorBase {
	static errorId = "invalid-report-settings" as const;
	constructor() {
		super("Nicht alle verwendeten Lohnarten wurden ausgefüllt");
	}
}

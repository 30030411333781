/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactNode } from "react";

export type ConfirmDialogConfig<TPayload> = {
	title?: string;
	text: ReactNode;
	cancelText?: string;
	confirmText?: string;
	payload?: TPayload;

	onConfirm: (payload: TPayload) => void;
	onCancel?: (payload: TPayload) => void;
};

export type DialogContextValue<T> = {
	showConfirmDialog: (config: ConfirmDialogConfig<T>) => void;
};

const DialogContext = React.createContext<DialogContextValue<any> | undefined>(
	undefined
);

export default DialogContext;

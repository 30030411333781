import { addMonths, subMonths, subYears } from "date-fns";
import { ReportSettingItem } from "../../models/ReportTypeSetting";
import { formatDateMonth, getMonthNumber } from "@service/util";

const now = () => new Date(Date.now());

export function getMonthOptions() {
	const monthOptions: string[] = [];
	let startDate = subYears(now(), 3);
	let startMonth = startDate.getMonth();
	let startYear = startDate.getFullYear();
	const endDate = addMonths(now(), 6);
	const endMonth = endDate.getMonth();
	const endYear = endDate.getFullYear();

	while (
		startYear < endYear ||
		(startYear === endYear && startMonth <= endMonth)
	) {
		monthOptions.push(formatDateMonth(startDate) + " " + startYear);
		startDate = addMonths(startDate, 1);
		startMonth = startDate.getMonth();
		startYear = startDate.getFullYear();
	}

	return monthOptions;
}

export function getPreviousReportingMonth() {
	const previousMonthDate = subMonths(now(), 1);
	const previousMonth = formatDateMonth(subMonths(now(), 1));
	return previousMonth + " " + previousMonthDate.getFullYear();
}

export function getYearMonth(selectedMonth: string) {
	const yearMonth = selectedMonth.split(" ");
	const month = getMonthNumber(yearMonth[0]).toString().padStart(2, "0");
	const year = yearMonth[1];
	return `${year}-${month}`;
}

export function getUpdatedReportTypeSettings(formValues: {
	[x: string]: string;
}) {
	const result: ReportSettingItem[] = Object.entries(formValues)
		.filter(([key]) => key !== "reportType")
		.map(([key, value]) => ({
			value: value.trim(),
			path: key.replace(/_/g, "."),
		}));

	return result;
}

import { Properties } from "@util/types";

export class FinancialAccountingSettings {
	constructor(props: Properties<FinancialAccountingSettings>) {
		this.liabilityAccount = props.liabilityAccount;
		this.liabilityAccountPaidByEmployer = props.liabilityAccountPaidByEmployer;
		this.expenseAccounts = props.expenseAccounts;
		this.clientNumber = props.clientNumber;
		this.consultantNumber = props.consultantNumber;
		this.postingKeys = props.postingKeys;
	}

	readonly liabilityAccount: string;
	readonly liabilityAccountPaidByEmployer: string;
	readonly expenseAccounts: Record<string, string>;
	readonly clientNumber: string;
	readonly consultantNumber: string;
	readonly postingKeys: Record<PostingKeyKey, string>;

	static fromJson(data: any): FinancialAccountingSettings {
		return new FinancialAccountingSettings({ ...data });
	}

	static labelsPostingKeys = {
		"pk.travel.5": "BU-Schlüssel 5%",
		"pk.travel.7": "BU-Schlüssel 7%",
		"pk.travel.19": "BU-Schlüssel 19%",
	} as const;
}

export type ExpenseAccountSystem = "skr03" | "skr04";

export type PostingKeyKey =
	keyof typeof FinancialAccountingSettings.labelsPostingKeys;

import React from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";

import { AccountingMonth } from "@models";
import { ExpandCard, Icon } from "@components";
import { formatYearMonth } from "@util";
import CloseMonthButton from "./CloseMonthButton";
import { DivisionRouteParams } from "@service/navigation/routes";
import UnlockMonthButton from "./UnlockMonthButton";
import LockMonthButton from "./LockMonthButton";
import AccountingMonthCardBody from "./AccountingMonthCardBody";

import { FaLock, FaLockOpen } from "react-icons/fa";
import { colors } from "@theme/theming";
import UncloseMonthButton from "./UncloseMonthButton";

type Props = {
	accountingMonth: AccountingMonth;
	onRefreshNeeded: () => void;
};

export default function AccountingMonthCard(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { accountingMonth } = props;

	const { statusDisplayName, nextStatusButtons, statusText, icon } =
		getStatusItems(accountingMonth, params.divisionId, props.onRefreshNeeded);

	const header = (
		<CardHeader>
			<ContentContainer>
				<h5>{formatYearMonth(accountingMonth.month)}</h5>
				<div>
					{icon} {statusDisplayName}
					<StatusText>{statusText}</StatusText>
				</div>
			</ContentContainer>
			<ButtonContainer>{nextStatusButtons}</ButtonContainer>
		</CardHeader>
	);

	return (
		<ExpandCard header={header}>
			<CardBody>
				<AccountingMonthCardBody accountingMonth={accountingMonth} />
			</CardBody>
		</ExpandCard>
	);
}
function getStatusItems(
	accountingMonth: AccountingMonth,
	divisionId: string,
	onRefreshNeeded: () => void
) {
	switch (accountingMonth.status) {
		case "closed":
			return {
				statusDisplayName: "abgeschlossen",
				nextStatusButtons: (
					<UncloseMonthButton
						accountingMonth={accountingMonth}
						divisionId={divisionId}
						onChangedStatus={onRefreshNeeded}
					/>
				),
				icon: <Icon.Success />,
				statusText: "",
			};
		case "locked":
			return {
				statusDisplayName: "gesperrt",
				nextStatusButtons: (
					<>
						<UnlockMonthButton
							accountingMonth={accountingMonth}
							divisionId={divisionId}
							onChangedStatus={onRefreshNeeded}
						/>
						<CloseMonthButton
							accountingMonth={accountingMonth}
							divisionId={divisionId}
							onChangedStatus={onRefreshNeeded}
						/>
					</>
				),
				statusText:
					"Es dürfen keine weitere Belege mehr von Mitarbeitenden hochgeladen werden. Belege sollten kontrolliert und der Monat abgeschlossen werden.",
				icon: <FaLock />,
			};
		case "open":
			return {
				statusDisplayName: "offen",
				nextStatusButtons: (
					<LockMonthButton
						accountingMonth={accountingMonth}
						divisionId={divisionId}
						onChangedStatus={onRefreshNeeded}
					/>
				),
				icon: <FaLockOpen />,
				statusText:
					"Mitarbeitende dürfen Belege für diesen Monat hochladen. Der Monat muss erst gesperrt werden, um ihn abrechnen zu können!",
			};
		default:
			return { statusDisplayName: "unbekannt", nextStatusButtons: <></> };
	}
}

const CardHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
`;

const CardBody = styled.div`
	padding: 32px;
`;

const ContentContainer = styled.div`
	flex-grow: 1;
`;
const ButtonContainer = styled.div`
	width: auto;
`;

const StatusText = styled.span`
	margin-left: 1rem;
	color: ${colors.gray.g400};
`;

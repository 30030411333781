import React, { useState } from "react";
import styled from "styled-components";
import {
	FormGroup,
	Label,
	Select,
	ActionButton,
	ButtonsContainer,
	Button,
	ErrorAlert,
	QueryStateIndicators,
	Form,
	FormContainer,
} from "@components";
import { Row, Col } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import ExpenseAccountSettings from "./ExpenseAccountSettings";
import { Division } from "@models";
import {
	ExpenseAccountSystem,
	FinancialAccountingSettings,
} from "@models/FinancialAccountingSettings";
import { QueryState } from "@hooks";
import { ReportFinancialSettingsResponse } from "@service/report/report.service";
import _ from "lodash";
import { Properties } from "@util/types";
import { FormValues } from "./types";

type Props = {
	division: Division;
	reportSettingsQuery: QueryState<void>;
	getReportSettings: (
		system?: ExpenseAccountSystem
	) => Promise<ReportFinancialSettingsResponse>;
	onClickCancel: () => void;
	onSubmit: (data: { [x: string]: string }) => void;
	defaultValues: {
		financialAccounting: FinancialAccountingSettings | null;
		financialSoftwareId: string | null;
	};
	expenseAccountNames: Record<string, string>;
};

const defaultEmptyAccountingValues: Properties<FinancialAccountingSettings> = {
	clientNumber: "",
	consultantNumber: "",
	liabilityAccount: "",
	liabilityAccountPaidByEmployer: "",
	expenseAccounts: {},
	postingKeys: {
		"pk.travel.5": "",
		"pk.travel.7": "",
		"pk.travel.19": "",
	},
};

export default function FinancialAccountForm({
	reportSettingsQuery,
	getReportSettings,
	onClickCancel,
	onSubmit,
	defaultValues,
	expenseAccountNames,
}: Props) {
	const defaults = _.defaultsDeep(
		{},
		defaultValues.financialAccounting,
		defaultEmptyAccountingValues
	);

	const formMethods = useForm<FormValues>({
		mode: "onChange",
		defaultValues: {
			...defaults,
			financialSoftwareId: defaultValues.financialSoftwareId,
			expenseAccounts: escapeKeys(defaults.expenseAccounts),
			postingKeys: escapeKeys(defaults.postingKeys),
		},
	});

	const { handleSubmit, register, formState, watch, reset, setValue } =
		formMethods;

	const { isValid, isDirty } = formState;
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	async function handleSetDefaultsExpenseAccounts(
		system: ExpenseAccountSystem
	) {
		const { financialAccounting: defaultsFinancialAccounting } =
			await getReportSettings(system);

		if (defaultsFinancialAccounting) {
			const { financialSoftwareId, clientNumber, consultantNumber } = watch();
			reset({
				...defaultsFinancialAccounting,
				clientNumber: clientNumber ?? "",
				consultantNumber: consultantNumber ?? "",
				expenseAccounts: escapeKeys(
					defaultsFinancialAccounting.expenseAccounts
				),
				postingKeys: escapeKeys(defaultsFinancialAccounting.postingKeys),
				financialSoftwareId,
			});
			// workaround for set form dirty after updating expenseAccounts
			setValue("financialSoftwareId", financialSoftwareId, {
				shouldDirty: true,
				shouldValidate: true,
			});
		} else {
			setErrorMessage("Fehler beim Laden der Standardwerte");
		}
	}

	const localOnSubmit = handleSubmit((escapedData: any) => {
		const unescapedData = {
			...escapedData,
			expenseAccounts: unescapeKeys(escapedData.expenseAccounts),
			postingKeys: unescapeKeys(escapedData.postingKeys),
		};
		onSubmit(unescapedData);
	});

	return (
		<FormProvider {...formMethods}>
			<FormContainer className="form">
				<QueryStateIndicators queryState={reportSettingsQuery} />
				<Form onSubmit={localOnSubmit}>
					<h2>Finanzbuchhaltung</h2>
					<Container>
						<FormGroup as={Row}>
							<Label column sm={6} htmlFor="financialSoftwareId">
								System
							</Label>
							<Col sm={6}>
								<Select
									id="financialSoftwareId"
									{...register("financialSoftwareId")}
								>
									<option value=""></option>
									<option value="datevFinAcc">DATEV</option>
								</Select>
							</Col>
						</FormGroup>
						<ExpenseAccountSettings
							handleSetDefaultsExpenseAccounts={
								handleSetDefaultsExpenseAccounts
							}
							expenseAccountNames={expenseAccountNames}
						/>
						<ButtonsContainer>
							<ActionButton
								type="submit"
								query={reportSettingsQuery}
								successContent={"Gespeichert"}
								disabled={!isDirty || !isValid}
							>
								Speichern
							</ActionButton>
							<Button type="button" onClick={onClickCancel}>
								Zurück
							</Button>
						</ButtonsContainer>
					</Container>
					{errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}
				</Form>
			</FormContainer>
		</FormProvider>
	);
}

const Container = styled.div`
	padding: 2rem;
`;

function escapeKeys<T extends Record<string, unknown>>(keys: T) {
	return _.mapKeys(keys, (value, key) => key.replaceAll(".", "-"));
}

function unescapeKeys<T extends Record<string, string>>(keys: T) {
	return _.mapKeys(keys, (value, key) => key.replaceAll("-", "."));
}

import React from "react";
import { Voucher, VoucherStatus } from "@models";
import Icon from "./icons";
import BusinessTrip, { TripStatus } from "@models/travel/BusinessTrip";
import { Tooltip } from "@components";

type Props = { voucher: Voucher } | { trip: BusinessTrip };

export default function StatusIcon(props: Props) {
	if ("voucher" in props) {
		if (props.voucher.isDraft) {
			return (
				<Tooltip
					id="draftVoucher"
					tooltip="Dieser Beleg wurde zurückgezogen und befindet sich nun im Entwurfsstatus. Dies kann passieren, wenn der Nutzer den Beleg zurückzieht, während du ihn im Backoffice überprüfst."
					data-testid="draftVoucherTooltip"
				>
					<Icon.Warning />
				</Tooltip>
			);
		}
		switch (props.voucher.reviewStatus) {
			case VoucherStatus.Approved:
				return <Icon.Status.Approved />;
			case VoucherStatus.Rejected:
				return <Icon.Status.Rejected />;
			case VoucherStatus.InReview:
				return <Icon.Status.InReview />;
			default:
				return null;
		}
	} else {
		switch (props.trip.status) {
			case TripStatus.Approved:
				return <Icon.Status.Approved />;
			case TripStatus.Rejected:
				return <Icon.Status.Rejected />;
			case TripStatus.InReview:
				return <Icon.Status.InReview />;
			case TripStatus.InReviewByEmployer:
				return <Icon.Status.InReviewByEmployer />;
			case TripStatus.ApprovedByEmployer:
				return <Icon.Status.ApprovedByEmployer />;
			case TripStatus.RejectedByEmployer:
				return <Icon.Status.RejectedByEmployer />;
			default:
				return null;
		}
	}
}

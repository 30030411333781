import AccountingMonth from "@models/AccountingMonth";
import { PaginationResult, QueryOptions } from "@service/types";
import { mapPaginationResponse } from "@service/util";
import { format } from "date-fns";
import * as apiService from "../api.service";

export async function getAccountingMonthsByDivision(
	divisionId: string,
	options: QueryOptions
): Promise<PaginationResult<AccountingMonth>> {
	const queryParams: any = {
		limit: options.limit,
		page: options.page,
		sort: options.sort,
		order: options.order,
	};
	const responseData = await apiService.GET(
		`division/${divisionId}/accountingMonth`,
		{
			params: queryParams,
		}
	);
	return mapPaginationResponse(responseData.data, (item) =>
		AccountingMonth.fromJson(item, divisionId)
	);
}

export async function lock(divisionId: string, monthDate: Date): Promise<void> {
	const monthStr = format(monthDate, "yyyy-MM");
	await apiService.POST(
		`division/${divisionId}/accountingMonth/${monthStr}/lock`
	);
}

export async function unlock(
	divisionId: string,
	monthDate: Date
): Promise<void> {
	const monthStr = format(monthDate, "yyyy-MM");
	await apiService.POST(
		`division/${divisionId}/accountingMonth/${monthStr}/unlock`
	);
}

export async function close(
	divisionId: string,
	monthDate: Date
): Promise<void> {
	const monthStr = format(monthDate, "yyyy-MM");
	await apiService.POST(
		`division/${divisionId}/accountingMonth/${monthStr}/close`
	);
}

export async function unclose(
	divisionId: string,
	monthDate: Date
): Promise<void> {
	const monthStr = format(monthDate, "yyyy-MM");
	await apiService.POST(
		`division/${divisionId}/accountingMonth/${monthStr}/unclose`
	);
}

import { ActionButton, QueryError } from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { AccountingMonth } from "@models";
import { DivisionRouteParams } from "@service/navigation/routes";
import { formatYearMonth } from "@util";
import { awaitWithDeferedCallback } from "@util/helpers";
import React from "react";
import { useRouteMatch } from "react-router-dom";

type Props = {
	accountingMonth: AccountingMonth;
	divisionId: string;
	onChangedStatus: () => void;
};

export default function CloseMonthButton(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { accountingMonthService } = useServices();
	const [handleCloseMonth, closeQueryState] = useAsyncCommand(
		() =>
			accountingMonthService.close(
				params.divisionId,
				props.accountingMonth.month
			),
		{
			rethrowError: false,
		}
	);

	const monthDisplayName = formatYearMonth(props.accountingMonth.month);
	const dlg = useDialog();

	const handleClickCloseButton = (e: React.MouseEvent) => {
		e.stopPropagation();
		dlg.showConfirmDialog({
			onConfirm: awaitWithDeferedCallback(
				handleCloseMonth,
				props.onChangedStatus
			),
			title: "Monatsabschluss",
			text: `Monat ${monthDisplayName} abschließen?  Dies lässt sich nicht mehr rückgängig machen.`,
			confirmText: "Abschließen",
		});
	};

	return (
		<>
			<QueryError queryState={closeQueryState} />
			<ActionButton onClick={handleClickCloseButton} query={closeQueryState}>
				Monat abschließen
			</ActionButton>
		</>
	);
}

import React, { useContext, useRef, useState } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import {
	CommonModal,
	DropDownToggleButton,
	ErrorAlert,
	ProductIcon,
	QueryStateIndicators,
	Tooltip,
} from "@components";
import { alertStyle } from "@components/ErrorAlert";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import {
	RootProductId,
	rootProductIds,
	UserWithBudgetMasters,
	getRootProductDescription,
} from "@models";
import BulkAddBudgetMasters from "./BulkAddBudgetMasters";
import ExportUsersCSV, { ExportUsersCSVHandle } from "./ExportUsersCSV";
import MoveUsersForm from "./MoveUsers/MoveUsersForm";
import UsersListContext from "../UsersListContext";
import { defer } from "@util/helpers";
import { addMonths } from "date-fns";
import { useDivisionFromPageContext } from "../hooks/useDivisionFromPageContext";
import DropDownMenu from "@components/DropDownMenu";
import { MonthClosedForChangesError } from "@service/common/errors";

type Props = {
	selectedUsers: UserWithBudgetMasters[];
	disabled?: boolean;
};

export default function BulkUserActionButton(props: Props) {
	const { selectedUsers, disabled } = props;
	const [showMoveUsersModal, setShowMoveUsersModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<
		RootProductId | undefined
	>();

	const userIds = selectedUsers.map((user) => user.userId);

	const { userService } = useServices();
	const [salutateUserFn, salutateQueryResult] = useAsyncCommand(() =>
		userService.salutateUser(userIds)
	);

	const {
		division: { divisionId },
	} = useDivisionFromPageContext();

	const [updateOverflowBudgetFn, updateOverflowBudgetResult] = useAsyncCommand(
		(month: Date) =>
			userService.updateExcessBudgetForUsers(userIds, month, divisionId)
	);

	const [deleteUsersFn, deleteQueryResult] = useAsyncCommand(() =>
		userService.deleteUsers(userIds)
	);

	const handleSelectAddBudgetMasters = (productId: string | null) => {
		setSelectedProduct((productId as RootProductId) ?? undefined);
	};

	const handleClose = () => setSelectedProduct(undefined);

	const usersListContext = useContext(UsersListContext);

	const deleteUsersDialog = useDialog();

	const handleDeleteUsers = () => {
		deleteUsersDialog.showConfirmDialog({
			title: "Benutzer löschen",
			text: `${selectedUsers.length} Benutzer wirklich löschen? Dies löscht alle für die Benutzer vorhandenen Daten, einschließlich der eingereichten Belege.`,
			confirmText: "Bestätigen",
			onConfirm: async () => {
				await deleteUsersFn();
				await defer(() => {
					usersListContext.triggerRefresh();
				});
			},
		});
	};

	const handleClickMoveUsers = () => setShowMoveUsersModal(true);
	const onClickCloseModal = () => {
		setShowMoveUsersModal(false);
	};

	const exportUsersRef = useRef<ExportUsersCSVHandle>(null);
	const handleExport = () => {
		exportUsersRef.current?.export(selectedUsers);
	};

	return (
		<>
			<Dropdown>
				<DropDownToggleButton
					id="dropdown-basic"
					variant="outline-primary"
					data-testid="bulkAddDropdown"
					size="sm"
					disabled={disabled}
				>
					<Tooltip
						id="bulkaction-tooltip"
						tooltip={
							disabled
								? "Benutzer markieren, um Massenbearbeitung zu aktivieren"
								: "Massenbearbeitung"
						}
					>
						<span>{selectedUsers.length + " Benutzer..."}</span>
					</Tooltip>
				</DropDownToggleButton>
				<DropDownMenu>
					<Dropdown.Item onSelect={salutateUserFn}>Einladen</Dropdown.Item>
					<Dropdown.Item as="button" onSelect={handleExport}>
						<ExportUsersCSV ref={exportUsersRef} />
						Exportieren (CSV)
					</Dropdown.Item>
					<Dropdown.Item as="button" onSelect={handleClickMoveUsers}>
						Verschieben
					</Dropdown.Item>
					<Dropdown.Item onSelect={handleDeleteUsers}>Löschen</Dropdown.Item>

					<Dropdown title="Budgets erstellen">
						<Dropdown.Toggle id="dropdown-basic" variant="no-outline">
							Budgets erstellen
						</Dropdown.Toggle>
						<DropDownMenu>
							{rootProductIds.map((productId) => (
								<Dropdown.Item
									key={productId}
									eventKey={productId}
									onSelect={handleSelectAddBudgetMasters}
								>
									<ProductIcon productId={productId} />{" "}
									{getRootProductDescription(productId).name}
								</Dropdown.Item>
							))}
						</DropDownMenu>
					</Dropdown>
					{location.hash === "#devMode" && (
						<>
							<Dropdown.Item
								onSelect={() =>
									updateOverflowBudgetFn(addMonths(new Date(), -1))
								}
							>
								Update Excess Budgets from the previous month to this
							</Dropdown.Item>
							<Dropdown.Item
								onSelect={() => updateOverflowBudgetFn(new Date())}
							>
								Update Excess Budgets from this month to the next
							</Dropdown.Item>
						</>
					)}
				</DropDownMenu>
			</Dropdown>

			{!!selectedProduct && (
				<BulkAddBudgetMasters
					users={selectedUsers}
					productId={selectedProduct}
					onClose={handleClose}
				/>
			)}

			{showMoveUsersModal && (
				<CommonModal handleClose={onClickCloseModal}>
					<MoveUsersForm
						onClickCloseModal={onClickCloseModal}
						users={selectedUsers}
					/>
				</CommonModal>
			)}
			<QueryStateIndicators queryState={salutateQueryResult} />
			{salutateQueryResult.state === "success" && (
				<Alert style={alertStyle} variant="success">
					Benutzer wurden eingeladen
				</Alert>
			)}
			<QueryStateIndicators queryState={deleteQueryResult} />
			{deleteQueryResult.state === "success" && (
				<>
					{!!deleteQueryResult.data.successful && (
						<ErrorAlert variant="success">
							{deleteQueryResult.data.successful} Benutzer wurde
							{deleteQueryResult.data.successful > 1 ? "n" : ""} gelöscht
						</ErrorAlert>
					)}
					{deleteQueryResult.data.errors.length && (
						<ErrorAlert
							style={
								deleteQueryResult.data.successful
									? { ...alertStyle, top: "70px" } // shift down when there are two messages
									: alertStyle
							}
							variant="danger"
						>
							{deleteQueryResult.data.errors
								.map((error) => {
									switch (error.type) {
										case "user-not-found":
											return `- ${error.user} - Benutzer wurde nicht gefunden`;
										case "accounting-month-closed":
											return `- ${error.user} - ${
												new MonthClosedForChangesError(error.month).displayText
											}`;
										case "unknown":
										default:
											return `- ${error.user} - Unbekannter Fehler beim Löschen des Benutzers`;
									}
								})
								.join("\n")}
						</ErrorAlert>
					)}
				</>
			)}
			<QueryStateIndicators queryState={updateOverflowBudgetResult} />
			{updateOverflowBudgetResult.state === "success" && (
				<Alert style={alertStyle} variant="success">
					Excess budgets were updated
				</Alert>
			)}
		</>
	);
}

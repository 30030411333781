import { ActionButton, QueryError } from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { AccountingMonth } from "@models";
import { DivisionRouteParams } from "@service/navigation/routes";
import { formatYearMonth } from "@util";
import { awaitWithDeferedCallback } from "@util/helpers";
import React from "react";
import { useRouteMatch } from "react-router-dom";

type Props = {
	accountingMonth: AccountingMonth;
	divisionId: string;
	onChangedStatus: () => void;
};

export default function UnlockMonthButton(props: Props) {
	const { params } = useRouteMatch<DivisionRouteParams>();
	const { accountingMonthService } = useServices();
	const [handleReopenMonth, reopenQueryState] = useAsyncCommand(
		() =>
			accountingMonthService.unlock(
				params.divisionId,
				props.accountingMonth.month
			),
		{
			rethrowError: true,
		}
	);

	const monthDisplayName = formatYearMonth(props.accountingMonth.month);
	const dlg = useDialog();

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		dlg.showConfirmDialog({
			onConfirm: awaitWithDeferedCallback(
				handleReopenMonth,
				props.onChangedStatus
			),
			title: "Monat entsperren",
			text: `Monat ${monthDisplayName} entsperren und wieder öffnen? Dies sollte im Regelfall nicht getan werden. 
				Wenn der Monat wieder geöffnet ist, können darin liegende Monatsbudgets und Belege wieder zeitweise bearbeitet werden. 
				Anschließend muss der Monat danach wieder zwingend abgeschlossen werden, damit er korrekt abgerechnet werden kann.`,
			confirmText: "Öffnen",
		});
	};

	return (
		<ActionButton
			variant="outline-danger"
			onClick={handleClick}
			query={reopenQueryState}
			size="sm"
		>
			Monat entsperren
			<QueryError queryState={reopenQueryState} />
		</ActionButton>
	);
}
